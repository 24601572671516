<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Form" icon="file-invoice"/>

    <div class="generalBox shadow-sm border">
        <div class="row">
            <div class="col-12 mb-3">Online Form Designer to generate pdf for signing and distribution</div>
            <div class="col-12">Coming soon..</div>
        </div>
    </div>
</template>

<script>
import { ref, inject, watch, onMounted, onUnmounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'FormOverview',
    components: {
        Alert, TopNavigation, Popper, Tooltip
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])

        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        return {
            func, alert, closeAlert, router, 
        }
    }
}
</script>

<style>

</style>